export default class {
    constructor() {
        this.listen();
    }

    actionCallbackCall() {
        if (typeof(ym) == 'function') {
            ym(90163410,'reachGoal','callback-call-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionCallbackContacts() {
        if (typeof(ym) == 'function') {
            ym(90163410,'reachGoal','callback-contacts-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionCallbackOrderDemo() {
        if (typeof(ym) == 'function') {
            ym(90163410,'reachGoal','callback-order-demo-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionClientsForm() {
        if (typeof(ym) == 'function') {
            ym(90163410,'reachGoal','clients-form-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionGetPresentation() {
        if (typeof(ym) == 'function') {
            ym(90163410,'reachGoal','form-get-presentation-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }
    actionSubscribeToUsFormSend() {
        if (typeof(ym) == 'function') {
            ym(87129039,'reachGoal','subscribe-to-us-form-send')
        }
        if (typeof(ga) == 'function') {
            // ga();
        }
    }

    listen() {
        window.addEventListener('eventCallbackCall', this.actionCallbackCall);
        window.addEventListener('eventCallbackContacts', this.actionCallbackContacts);
        window.addEventListener('eventCallbackOrderDemo', this.actionCallbackOrderDemo);
        window.addEventListener('eventClientsForm', this.actionClientsForm);
        window.addEventListener('eventGetPresentation', this.actionGetPresentation);
        window.addEventListener('eventSubscribeToUsFormSend', this.actionSubscribeToUsFormSend);
    }
}
