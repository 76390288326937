export default class {
    mapContact = document.getElementById('map');
    map;
    infowindow;
    allOffices;
    domain;
    constructor() {
        if (this.mapContact != null && typeof (this.mapContact) != undefined) {
            google.maps.event.addDomListener(window, 'load', this.initMap());
        } else {
            return;
        }
    }
    initMap() {
        this.allOffices = [];
        this.domain = '';
        let options = {
            center: new google.maps.LatLng(49.504034, 67.267951),
            zoom: 3,
            streetViewControl: false,
            scrollwheel: false,
            mapTypeId: 'terrain',
            styles: [{
                    "stylers": [{
                            "saturation": -100
                        },
                        {
                            "gamma": 1
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "labels.text",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "poi.place_of_worship",
                    "elementType": "labels.text",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "poi.place_of_worship",
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [{
                        "visibility": "simplified"
                    }]
                },
                {
                    "featureType": "water",
                    "stylers": [{
                            "visibility": "on"
                        },
                        {
                            "saturation": 50
                        },
                        {
                            "gamma": 0
                        },
                        {
                            "hue": "#50A5D1"
                        }
                    ]
                },
                {
                    "featureType": "administrative.neighborhood",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#333333"
                    }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text",
                    "stylers": [{
                            "weight": 0.5
                        },
                        {
                            "color": "#333333"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "labels.icon",
                    "stylers": [{
                            "gamma": 1
                        },
                        {
                            "saturation": 50
                        }
                    ]
                }
            ]
        }
        let bounds = new google.maps.LatLngBounds();
        this.map = new google.maps.Map(this.mapContact, options);
        let listener = google.maps.event.addListener(this.map, "tilesloaded", () => {
            this.map.setZoom(4.98);
            if (document.documentElement.clientWidth <= 768) {
                this.map.setZoom(2);
            }
            google.maps.event.removeListener(listener);
        });

        google.maps.event.addListenerOnce(this.map, 'idle', function() {
            this.map.fitBounds(bounds);
        });

        this.request = axios.create({
                method: 'get',
                baseURL: `${this.domain}/api/v1`,
                headers: {
                    'Accept-Language': document.documentElement.lang
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    });
                },
            }),
            this.request('/offices')
            .then(response => {
                this.allOffices = response.data.data;
                this.createMarker();
            });
    }

    determineLanguageDocument() {
        let scriptTag = document.createElement('script');
        let currentLang = document.documentElement.lang;
        let scriptSrc = 'https://maps.googleapis.com/maps/api/js?language=' + currentLang + '&key=AIzaSyAjhR0jh62LxpjO3QTxKV5Khvg1N18GDSY';

        scriptTag.setAttribute('src', scriptSrc);
        document.querySelectorAll('.section-map').forEach(section => {
            section.appendChild(scriptTag);
        });
    }

    createMarker() {
        const icon = '/images/base_content/svg/mark.svg';
        const iconHover = '/images/base_content/svg/mark-hover.png';
        const pointDesc = document.querySelector('.map-point-desc');
        this.allOffices.forEach(office => {
            if (office.show_on_map === false) return;
            let markerOptions = {
                map: this.map,
                position: new google.maps.LatLng(office.latitude, office.longitude),
                title: office.title !== "" ? office.title : office.city_name,
                icon: icon,
            };
            const marker = new google.maps.Marker(markerOptions);
            const phone = office.phone !== null ? office.phone : '';
            const title = office.title !== null ? office.title : office.name;
            const infoWindowOptions = {
                content: '<div class="infowindow">' +
                    '<h3>' + title +
                    '</h3><div>' + office.address + '</br>' + phone + '</div>' +
                    '<span>' + office.latitude + ' ' + office.longitude + '</span>' +
                    '<img class="close-point" src="/images/base_content/svg/close-gray.svg"' +
                    '</div>',
                pixelOffset: new google.maps.Size(0, -3),
            };
            this.infowindow = new google.maps.InfoWindow({});
            this.infowindow.setOptions(infoWindowOptions);
            marker.infoWindowOptions = infoWindowOptions;
            marker.infoWindow = this.infowindow;
            pointDesc.addEventListener('click', (event) => {
                let closePoint = event.currentTarget.querySelector('.close-point');
                closePoint.parentElement.classList.add('d-none');
            });
            // window.addEventListener('resize', function (event) {
            if (document.documentElement.clientWidth <= 768) {
                google.maps.event.addListener(marker, 'click', function () {
                    pointDesc.innerHTML = this.infoWindowOptions.content;
                    pointDesc.classList.add('opened');
                });
            } else {
                google.maps.event.addListener(marker, 'click', function () {
                    this.infoWindow.close();
                    marker.infoWindow.setOptions(this.infoWindowOptions);
                    this.infoWindow.open(this.map, this);
                });
                google.maps.event.addListener(marker, "mouseover", function () {
                    marker.setIcon(iconHover);
                });
                google.maps.event.addListener(marker, "mouseout", function () {
                    marker.setIcon(icon);
                });
            }
            // });

            this.map.setCenter(new google.maps.LatLng(55.434034, 43.467951));
            return marker;
        })
    }
}
